import { push } from "connected-react-router";
import { all, fork, put, takeEvery, select, call } from "redux-saga/effects";
import moment from "moment";
import React from "react";
import i18next from "i18next";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { get, pick, last } from "lodash-es";
import Compressor from "compressorjs";

import { Spinners, Modals } from "../ui/ui.constants";
import {
  authorizedRoutes,
  unAuthorizedRoutes,
  API_MESSAGE,
  moneyTransferRoutes,
} from "../../constants";
import {
  REQUEST,
  SUCCESS,
  FAILURE,
} from "../../utils/types/create-constants.types";
import { getMerchantSessionKeySaga } from "../common/common.saga";
import { requestSagaHandler } from "../../utils/sagas";
import CountryService from "../../services/country-service/country.service";
import phoneService from "../../services/phone.service";
import { api } from "../../utils/apiClient";
import environment from "../../../../environment";
import {
  toggleSpinner,
  toggleModal,
  setDocumentUploadRedirectRoute,
} from "../ui/ui.actions";
import toast from "../../utils/toast";
import { ICreateAccountFormValues } from "../../composed-components/CreateUserAccountForm";
import { complianceRulesSelector } from "../transfer/transfer.selectors";
import { sourceCountrySelector } from "../organization/organization.selectors";
import { organizationWebClients } from "../organization/organization.constants";
import {
  getMobileConfigActions,
  getUserActionNeededActions,
} from "../organization/organization.actions";
import { getRecipientsActions } from "../recipient/recipient.actions";
import { setSourceCountryAction } from "../transfer/transfer.actions";
import { GreetingToast } from "../../components/GreetingToast";
import { IVerficationDocument } from "../transfer/transfer.types";
import documentService from "../../services/document.service";
import { selectRedirectRouteAfterDocUpload } from "../ui/ui.selectors";

import {
  userDataSelector,
  userIdSelector,
  userCountrySelector,
  userDocumentsSelector,
  signupDocsSelector,
  requestErrorSelector,
} from "./user.selectors";
import {
  userLoginActions,
  userSignupLastStepActions,
  changePasswordActions,
  getUserDataActions,
  editUserActions,
  uploadDocumentsActions,
  getUserDocumentsActions,
  setDocumentsAction,
  clearUserDataAction,
  userResetForgottenPasswordActions,
  saveUserNotificationOptionsActions,
  getUserContactOptionsActions,
  resendVerificationCodeActions,
  resendVerificationCodeByEmailActions,
  verifyUserPhoneActions,
  sendVerificationCodeUnAuthActions,
  getAddressByZipCodeActions,
  getUserTransactionsActions,
  sendEmailToOrganizationActions,
  verifyUserEmailActions,
  verifyReferralCodeActions,
  getUserCardsActions,
  createCreditCardActions,
  VERIFY_USER_CARD,
  removeCreditCardActions,
  setSignupDocumentssAction,
  userTwoFAGenerateQRActions,
  userTwoFAVerifyActions,
  userTwoFADisableActions,
  getUserDocumentCheckIdReportActions,
  userResetForgottenPasswordEmailActions,
  setRejectionFlowAction,
  updateRejectionConditionsAction,
} from "./user.actions";
import {
  LOGIN_ENDPOINT,
  userLoginTypes,
  userSignupLastStepTypes,
  getUserTransactionsTypes,
  SIGNUP_ENDPOINT,
  GET_USER_DOCUMENTS_ENDPOINT,
  GET_USER_DATA_ENDPOINT,
  UPLOAD_IMAGE_ENDPOINT,
  getUserDataTypes,
  editUserTypes,
  getUserDocumentsTypes,
  uploadDocumentsTypes,
  docTypes,
  EDIT_USER_ENDPOINT,
  USER_LOGOUT,
  userResetForgottenPasswordTypes,
  RESET_FORGOTTEN_PASSWORD_ENDPOINT,
  USER_CHANGE_PASSWORD_ENDPOINT,
  saveUserNotificationOptionsTypes,
  SAVE_USER_NOTIFICATION_OPTIONS_ENDPOINT,
  GET_USER_TRANSACTIONS_ENDPOINT,
  getUserContactOptionsTypes,
  GET_USER_CONTACT_OPTIONS_ENDPOINT,
  RESEND_VERIFICATION_CODE_ENDPOINT,
  RESEND_VERIFICATION_CODE_BY_EMAIL_ENDPOINT,
  resendVerificationCodeTypes,
  resendVerificationCodeByEmailTypes,
  verifyUserPhoneTypes,
  VERIFY_USER_PHONE_ENDPOINT,
  sendVerificationCodeUnAuthTypes,
  SEND_VERIFICATION_CODE_UNAUTH_ENDPOINT,
  GET_ADDRESS_BY_ZIPCODE_ENDPOINT,
  getAddressByZipCodeTypes,
  sendEmailToOrganizationTypes,
  SEND_EMAIL_TO_ORGANIZATION_ENDPOINT,
  VERIFY_USER_EMAIL_ENDPOINT,
  verifyUserEmailTypes,
  verifyReferralCodeTypes,
  VERIFY_REFERRAL_CODE_ENDPOINT,
  getUserCardsTypes,
  GET_USER_CARDS_ENDPOINT,
  CREATE_USER_CARD_ENDPOINT,
  REMOVE_USER_CARD_ENDPOINT,
  createCreditCardTypes,
  removeCreditCardTypes,
  userTwoFAGenerateQRTypes,
  TWOFA_GENERATE_QR_ENDPOINT,
  TWOFA_VERIFY_ENDPOINT,
  userTwoFAVerifyTypes,
  userTwoFADisableTypes,
  TWOFA_DISABLE_ENDPOINT,
  EDIT_USER_ONFIDO_ENDPOINT,
  getUserDocumentCheckIdReportTypes,
  GET_USER_DOCUMENT_CHECK_ID_REPORT_ENDPOINT,
  userResetForgottenPasswordEmailTypes,
  RESET_FORGOTTEN_PASSWORD_EMAIL_ENDPOINT,
  changePasswordTypes,
  USER_UPDATE_REJECTION_CONDITIONS,
} from "./user.constants";
import {
  ILoginFormValues,
  IUserSignup,
  IUserDoc,
  ITwoFAGenerateQRFormValues,
  IUserData,
  RejectionReasonEnum,
} from "./user.types";

function* userChangePasswordBodyGetter({ currentPassword, password }: any) {
  const { userId } = yield select(userDataSelector);
  return {
    data: {
      oldPassword: currentPassword,
      newPassword: password,
      userId,
    },
  };
}

/* boody getters ******/
const loginBodyGetter = ({
  phoneCode,
  phone = "",
  password,
  email,
  verificationCode,
}: ILoginFormValues) => {
  const phoneNumber = get(
    parsePhoneNumberFromString(phone, phoneCode as any),
    "number",
  );

  return {
    data: {
      organizationId: environment.organizationId,
      phoneNumber,
      password,
      email,
      verificationCode,
    },
  };
};

function* twoFAGenerateQRBodyGetter() {
  const { selectedUserId } = yield select(userDataSelector);
  return {
    data: {
      organizationId: environment.organizationId,
      userId: selectedUserId,
    },
  };
}

const twoFAVerifyBodyGetter = (payload: {
  insertedVerificationCode: string;
}) => {
  const { insertedVerificationCode } = payload;
  return {
    data: {
      verificationCode: insertedVerificationCode,
    },
  };
};

const signupBodyGetter = (payload: {
  values: ICreateAccountFormValues;
  verificationCode: string;
}): { data: IUserSignup } => {
  const { values, verificationCode } = payload;
  const {
    phoneCode,
    phone,
    POST,
    SOCIAL_MEDIA,
    PHONE,
    passwordConfirm,
    termsAccepted,
    residentCountry,
    isUploaded,
    idType,
    idExpiryDate,
    idIssuedDate,
    idNumber,
    idIssueByCountry,
    ...user
  } = values;

  const notifications: { [option: string]: boolean } = {
    POST: !POST,
    SOCIAL_MEDIA,
    PHONE,
    SMS: true,
    EMAIL: true,
  };
  const userContactOptions = Object.keys(notifications).filter(
    key => notifications[key],
  );
  const data: IUserSignup = {
    ...user,
    origin: "WEB",
    country: phoneCode,
    phoneNumber: phoneService.joinPhoneNumber(phoneCode, phone),
    accounts: [{ accountType: "REM_SENDER" }],
    roles: ["USER"],
    userContactOptions,
    verificationCode,
    organizationId: environment.organizationId,
    userAgent: window.navigator.userAgent,
  };

  if (residentCountry !== "GB") {
    data.idType = idType;
    data.idExpiryDate = idExpiryDate;
    data.idIssuedDate = idIssuedDate;
    data.idNumber = idNumber;
    data.idIssueByCountry = idIssueByCountry;
  }
  return { data };
};

function* concatUrlWithUserId(url: string) {
  const { userId } = yield select(userDataSelector);

  return (
    userId && {
      url: `${url}/${userId}`,
    }
  );
}

function* getUserIdBodyGetter() {
  const userId = yield select(userIdSelector);
  return { data: { userId } };
}

const resetForgottenPasswordBodyGetter = (phoneNumber: string) => {
  return {
    data: {
      organizationId: environment.organizationId,
      phoneNumber,
    },
  };
};

const resetForgottenPasswordEmailBodyGetter = (email: string) => {
  return {
    data: {
      organizationId: environment.organizationId,
      email,
    },
  };
};

function* saveUserNotificationOptionsBodyGetter(
  userContactOptionsList: string[],
) {
  const { userId } = yield select(userDataSelector);

  return {
    data: {
      userId,
      userContactOptionsList,
    },
  };
}

function* getUserContactOptionsTypesBodyGetter() {
  const { userId } = yield select(userDataSelector);

  return {
    data: {
      organizationId: environment.organizationId,
      userId,
    },
  };
}

function* sendVerificationCodeBodyGetter(payload: {
  country: string;
  phoneNumber: string;
  reCaptchaToken: string;
}) {
  const { country, phoneNumber, reCaptchaToken } = payload;
  const phoneCountry = "GB";

  return {
    data: {
      organizationId: environment.organizationId,
      origin: "WEB",
      country,
      phoneCountry,
      phoneNumber,
      reCaptchaToken,
    },
  };
}

function* sendVerificationCodeByEmailBodyGetter(payload: {
  firstName: string;
  email: string;
  country: string;
  phoneNumber: string;
  reCaptchaToken: string;
}) {
  const { firstName, email, country, phoneNumber, reCaptchaToken } = payload;
  const phoneCountry = "GB";

  return {
    data: {
      organizationId: environment.organizationId,
      country,
      firstName,
      email,
      phoneCountry,
      phoneNumber,
      reCaptchaToken,
    },
  };
}

function* verifyUserPhoneBodyGetter(verificationCode: string) {
  const { userId } = yield select(userDataSelector);

  return {
    data: {
      organizationId: environment.organizationId,
      userId,
      verificationCode,
    },
  };
}

function* editUserBodyGetter(payload: any) {
  const userData = yield select(userDataSelector);
  const {
    agentData,
    confirmPassword,
    phoneCountry,
    phone,
    recipients,
    documents,
    organizationData,
    isEmailExist,
    password,
    idIssueByCountry,
    idNumber,
    idType,
    creditDaysToPay,
    idExpiryDateDay,
    idIssuedDateDay,
    idIssuedDateMonth,
    idIssuedDateYear,
    idExpiryDateMonth,
    idExpiryDateYear,
    ...rest
  } = payload;

  const request = {
    ...rest,
    userId: userData.userId,
    senderId: undefined,
    phoneNumber: payload.phoneNumber
      ? phoneService.joinPhoneNumber(phoneCountry, payload.phoneNumber)
      : userData.phone,
    password: payload.password,
    idIssueByCountry,
    idType,
    idNumber,
    country: userData.country,
  };
  return { data: request };
}

function* getAddressByZipCodeBodyGetter(zipCode: string) {
  const userData = yield select(userDataSelector);

  return {
    data: {
      organizationId: environment.organizationId,
      country: userData.country || "GB",
      postCode: zipCode,
    },
  };
}
const startDateDefault = moment()
  .subtract(1, "years")
  .format("YYYY-MM-DD");
const endDateDefault = moment().format("YYYY-MM-DD");

function* getTransactionsBodyGetter(payload: {
  pageNum: number;
  startDate: string;
  endDate: string;
}) {
  // const { pageNum, startDate, endDate } = payload;
  const userId = yield select(userIdSelector);
  const organizationId = environment.organizationId;

  // const params = {
  //   pageNum: pageNum || 0,
  //   // TODO connect other filters
  //   startDate: `${startDate || startDateDefault} 00:00:00`,
  //   endDate: `${endDate || endDateDefault} 23:59:00`,
  //   sortField: "DATE",
  //   sortDirection: "DESC",
  // };

  return {
    data: {
      userId,
      organizationId,
      // fullResult: false,
      // ...params,
    },
  };
}

function* sendEmailToOrganizationBodyGetter({
  emailMessage,
}: {
  [prop: string]: string;
}) {
  const userData = yield select(userDataSelector);
  const organizationId = environment.organizationId;
  return {
    data: {
      senderName: `${get(userData, "firstName")} ${get(userData, "lastName")}`,
      senderEmail: get(userData, "email"),
      organizationId,
      emailMessage,
    },
  };
}
/********************************* */

interface IBlob extends Blob {
  name: string;
}

function compressFile(file: any): Promise<IBlob> {
  return new Promise((res, rej) => {
    // tslint:disable-next-line: no-unused-expression
    if (file.type !== "application/pdf") {
      const comp = new Compressor(file, {
        quality: 0.4,
        success(result: IBlob) {
          res(result);
        },
        error(err) {
          rej(err);
        },
      });
    } else {
      res(file);
    }
  });
}

function* uploadImage({ file, subFiles, documentType }: IVerficationDocument) {
  try {
    const country = yield select(userCountrySelector);
    const userId = yield select(userIdSelector);

    if (!file) {
      return;
    }

    const formData: FormData = new FormData();
    yield compressFile(file).then((res: IBlob) => {
      console.log(res);
      formData.append("file", res, res.name);
      formData.append("userId", userId);
      formData.append("country", country);
      formData.append(
        "description",
        documentType instanceof Object
          ? documentType.documentType
          : documentType,
      );
    });

    if (subFiles !== undefined) {
      for (const sfile of subFiles) {
        yield compressFile(sfile.file).then((sres: IBlob) => {
          formData.append("subFiles", sres, sres.name);
        });
      }
    }
    return api.post(UPLOAD_IMAGE_ENDPOINT, formData);
  } catch (err) {
    throw new Error(
      `${i18next.t("fail_to_load")} ${documentService.getDocumentLabel(
        documentType,
      )}`,
    );
  }
}

function* uploadUserDocs({ payload: documents }: any) {
  try {
    const activeComplianceRule = yield select(complianceRulesSelector);
    yield all(
      documents.map((doc: IVerficationDocument) => call(uploadImage, doc)),
    );
    yield put(uploadDocumentsActions.success());
    toast.success({ message: i18next.t("your_documents_have_been_uploaded") });
    if (activeComplianceRule) {
      switch (activeComplianceRule.action) {
        case "REQUIRE_DOC": {
          yield put(
            push(moneyTransferRoutes.COMPLIANCE_REVIEW_REQUIRE_DOC_SCREEN),
          );
          break;
        }
        case "REQUIRE_DOC_MANUAL": {
          yield put(push(moneyTransferRoutes.EXTRA_DETAILS));
          break;
        }
        default: {
          yield put(
            push(moneyTransferRoutes.COMPLIANCE_REVIEW_REQUIRE_DOC_SCREEN),
          );
          break;
        }
      }
    }
  } catch ({ message }) {
    yield put(uploadDocumentsActions.failure());
    toast.error({ message });
  }
}

function* getUserDocuments() {
  yield put(getUserDocumentsActions.request());
}

function* getUserCards() {
  yield put(getUserCardsActions.request());
}

function* handleLogin() {
  const user: IUserData = yield select(({ user: { userData } }) => userData);
  const authToken = yield select(({ user: { token } }) => token);

  if (authToken != null) {
    if (user.country !== "GB") {
      yield put(clearUserDataAction());
      window.open(
        get(organizationWebClients, user.country) + "login/email",
        "_self",
      );
      return;
    }
    toast.primary({
      message: (
        <GreetingToast message={i18next.t("login_welcome") + user.firstName} />
      ),
      autoClose: 20000,
    });
    yield put(getRecipientsActions.request());
    yield put(getMobileConfigActions.request());
    yield getUserData();
  }
}

function* handleLoginError() {
  const message: string = yield select(requestErrorSelector);
  if (message != null) {
    toast.error({ message });
  }
}

function* handleTWOFAGenerateQRCode() {
  yield put(toggleModal(Modals.TWOFA_GENERATE_QR_CODE_MODAL, true));
}

function* handleToggleTwoFAVerifyModal() {
  const twoFAVerified = yield select(({ user: { verified } }) => verified);

  if (twoFAVerified) {
    yield put(toggleModal(Modals.TWOFA_GENERATE_QR_CODE_MODAL, false));
    yield getUserData();
  } else {
    toast.error({
      message: "Wrong verification code, try again.",
      autoClose: 20000,
    });
  }
}

function* handleToggleTwoFADisableModal() {
  yield put(toggleModal(Modals.TWOFA_DISABLE_MODAL, false));
  yield getUserData();
}

function* getUserData() {
  const authToken = yield select(({ user: { token } }) => token);

  if (authToken) {
    yield put(getUserDataActions.request());
  }
}

function* httpLogOutRequest() {
  const user = yield select(userDataSelector);

  try {
    yield call(api.post, `/auth/logout`, {
      organizationId: user.organizationData.organizationId,
      loggedInUserId: user.userId,
      userId: user.userId,
    });
  } catch {
    // Who cares?
  }
}

function* logOutSaga() {
  yield httpLogOutRequest();
  yield put(clearUserDataAction());
  yield put(push(unAuthorizedRoutes.LOGIN_ROOT));
}

function* getImageById(documentId: string, fileExt: string) {
  try {
    const { data: base64Data } = yield call(
      api.get,
      `/user/getDocument?id=${documentId}&base64`,
    );

    const preview = `data:image/${fileExt};base64,${base64Data}`;
    return preview;
  } catch (err) {
    return null;
  }
}

function* getDocumentsSources() {
  try {
    yield put(toggleSpinner(Spinners.GET_USER_DOCUMENTS_SPINNER, true));

    const docs = yield select(userDocumentsSelector);

    const rejectDocs = docs.filter((document: any) => {
      return (
        document.complianceStatus &&
        (document.complianceStatus.toLocaleLowerCase() === "rejected" ||
          document.complianceStatus.toLocaleLowerCase() === "suspected") &&
        (document.documentType === "ID" ||
          document.documentType === "ID_BACK_SIDE")
      );
    });

    const documentsWithSources = [];
    for (const doc of docs) {
      const { fileExt, documentId } = doc;
      const preview = yield getImageById(documentId, fileExt);

      if (preview) {
        documentsWithSources.push({
          ...doc,
          preview,
        });
      }
    }
    yield put(setDocumentsAction(documentsWithSources));

    if (rejectDocs && rejectDocs.length) {
      yield put(push(moneyTransferRoutes.REJECTF_FLOW));
    }

    const signupDocuments = yield select(signupDocsSelector);
    if (signupDocuments && signupDocuments[docTypes.ID]) {
      yield put(setSignupDocumentssAction(null));
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    yield put(toggleSpinner(Spinners.GET_USER_DOCUMENTS_SPINNER, false));
  }
}

function* autologinUserAndCloseVerificationModal() {
  yield closeVerificationModalSaga();

  const userData = yield select(userDataSelector);
  yield put(userLoginActions.request(userData));
  yield call(getUserData);
}

function* closeVerificationModalSaga() {
  yield put(toggleModal(Modals.PHONE_VERIFICATION_MODAL, false));
}

function* successUserDataSaga(action: { type: string; payload: any }) {
  const signupDocuments = yield select(signupDocsSelector);
  if (signupDocuments && signupDocuments[docTypes.ID]) {
    yield uploadImage(signupDocuments[docTypes.ID]);
    yield uploadImage(signupDocuments[docTypes.ID_BACK_SIDE]);
  }
  yield getUserDocuments();
  yield getUserCards();
  const sourceCountry = yield select(sourceCountrySelector);
  if (sourceCountry !== action.payload.userData.country) {
    yield put(setSourceCountryAction(action.payload.userData.country));
  }

  if (action.payload.userData.updateIdDataPanelDisplayed) {
    yield put(getUserDocumentCheckIdReportActions.request());
  }

  const {
    payload: { userData },
  } = action;

  if (userData.complianceStatus.toLowerCase() === "expired") {
    yield put(
      setRejectionFlowAction({
        reason: RejectionReasonEnum.ID_EXPIRED,
        isCancelable: false,
      }),
    );

    yield put(push(moneyTransferRoutes.REJECTF_FLOW));
  } else {
    yield put(push(authorizedRoutes.MONEY_TRANSFER));
  }
}

function* toggleUpdateIdDataModal() {
  yield put(toggleModal(Modals.UPDATE_ID_DATA_MODAL, true));
}

function* closeUpdateIdDataModal() {
  yield put(toggleModal(Modals.UPDATE_ID_DATA_MODAL, false));
}
// function* checkPhoneVerificationSaga() {
//   const { phoneVerified } = yield select(userDataSelector);

//   if (!phoneVerified) {
//     const { country, phone: phoneNumber } = yield select(userDataSelector);
//     yield put(resendVerificationCodeActions.request({ country, phoneNumber }));
//     yield put(toggleModal(Modals.PHONE_VERIFICATION_MODAL, true));
//   }
// }

function* closePhoneVerificationModalSaga() {
  yield put(toggleModal(Modals.PHONE_VERIFICATION_MODAL, false));
}

function* getUserTransactions(): any {
  yield put(getUserTransactionsActions.request({}));
}

function* handleUploadDocsSuccess() {
  const nextRoute = yield select(selectRedirectRouteAfterDocUpload);
  if (nextRoute) {
    yield put(push(nextRoute));
    yield put(setDocumentUploadRedirectRoute(null));
  }
}

const userChangePasswordFork = fork(requestSagaHandler.post, {
  actions: changePasswordActions,
  paramsGetter: userChangePasswordBodyGetter,
  types: changePasswordTypes,
  url: USER_CHANGE_PASSWORD_ENDPOINT,
  toastMessage: () => ({
    SUCCESS: i18next.t("password_has_been_changed"),
    FAILURE: i18next.t("password_changing_failed"),
  }),
});

const userLoginFork = fork(requestSagaHandler.post, {
  actions: userLoginActions,
  paramsGetter: loginBodyGetter,
  types: userLoginTypes,
  url: LOGIN_ENDPOINT,
});

const userTwoFAGenerateQRFork = fork(requestSagaHandler.post, {
  actions: userTwoFAGenerateQRActions,
  paramsGetter: twoFAGenerateQRBodyGetter,
  types: userTwoFAGenerateQRTypes,
  url: TWOFA_GENERATE_QR_ENDPOINT,
});

const userTwoFAVerifyFork = fork(requestSagaHandler.post, {
  actions: userTwoFAVerifyActions,
  paramsGetter: twoFAVerifyBodyGetter,
  types: userTwoFAVerifyTypes,
  url: TWOFA_VERIFY_ENDPOINT,
});

const userTwoFADisableFork = fork(requestSagaHandler.post, {
  actions: userTwoFADisableActions,
  paramsGetter: twoFAVerifyBodyGetter,
  types: userTwoFADisableTypes,
  url: TWOFA_DISABLE_ENDPOINT,
});

const userSignupFork = fork(requestSagaHandler.post, {
  actions: userSignupLastStepActions,
  paramsGetter: signupBodyGetter,
  types: userSignupLastStepTypes,
  url: SIGNUP_ENDPOINT,
  toastMessage: {
    FAILURE: API_MESSAGE,
  },
  spinner: Spinners.SIGN_UP_SPINNER,
});

const resendVerificationCodeFork = fork(requestSagaHandler.post, {
  actions: resendVerificationCodeActions,
  paramsGetter: sendVerificationCodeBodyGetter,
  types: resendVerificationCodeTypes,
  url: RESEND_VERIFICATION_CODE_ENDPOINT,
  toastMessage: () => ({
    [SUCCESS]: i18next.t("verification_code_has_been_sent"),
  }),
});

const resendVerificationCodeByEmailFork = fork(requestSagaHandler.post, {
  actions: resendVerificationCodeByEmailActions,
  paramsGetter: sendVerificationCodeByEmailBodyGetter,
  types: resendVerificationCodeByEmailTypes,
  url: RESEND_VERIFICATION_CODE_BY_EMAIL_ENDPOINT,
  toastMessage: () => ({
    [SUCCESS]: i18next.t("verification_code_has_been_sent"),
  }),
});

const sendVerificationCodeUnAuthFork = fork(requestSagaHandler.post, {
  actions: sendVerificationCodeUnAuthActions,
  paramsGetter: sendVerificationCodeBodyGetter,
  types: sendVerificationCodeUnAuthTypes,
  url: SEND_VERIFICATION_CODE_UNAUTH_ENDPOINT,
  toastMessage: () => ({
    [SUCCESS]: `${i18next.t("verification_code_has_been_sent")}\n${i18next.t(
      "term_verification_code",
    )}`,
    [FAILURE]: "Verification code has not been sent! Try later!",
  }),
});

const editUserFork = fork(requestSagaHandler.post, {
  actions: editUserActions,
  paramsGetter: editUserBodyGetter,
  types: editUserTypes,
  url: EDIT_USER_ENDPOINT,
  toastMessage: () => ({
    [SUCCESS]: i18next.t("your_details_have_been_saved"),
    [FAILURE]: i18next.t("failed_to_save_changes"),
  }),
});

const getUserDocumentCheckIdReportFork = fork(requestSagaHandler.post, {
  actions: getUserDocumentCheckIdReportActions,
  types: getUserDocumentCheckIdReportTypes,
  url: GET_USER_DOCUMENT_CHECK_ID_REPORT_ENDPOINT,
});

function* cardVerificationSaga({ card }: any) {
  try {
    const cardDetails = {
      securityCode: card.cvc,
      cardholderName: card.cardholderName,
      cardNumber: card.number.replace(/\s/g, ""),
      expiryDate: `${card.expirationMonth}${card.expirationYear}`,
    };
    const userId = yield select(userIdSelector);
    const merchantSessionKey = yield getMerchantSessionKeySaga();

    const cardNumber = card.number.replace(/\s/g, "");
    const lastFourDigits = cardNumber
      .substr(cardNumber.length - 4)
      .padStart(cardNumber.length, "*");

    const { cardIdentifier, ...data } = yield new Promise((resolve, reject) => {
      (window as any)
        .sagepayOwnForm({ merchantSessionKey })
        .tokeniseCardDetails({
          cardDetails,
          onTokenised: (result: any) => {
            if (result.success) {
              resolve(result);
            } else {
              const message = get(result, "errors[0].message");
              reject({ message });
              toast.error({ message });
            }
          },
        });
    });

    yield put(
      createCreditCardActions.request({
        userId,
        userCardData: Object.assign(
          pick(card, [
            "expirationMonth",
            "expirationYear",
            "cardholderName",
            "issuer",
          ]),
          {
            lastFourDigits,
            tokenizedCard: cardIdentifier,
            fundingSource: "CREDIT_CARD",
            fundingSourceProcessorType: "CC_SAGE_PAY",
          },
        ),
      }),
    );
  } catch (err) {
    console.log(err);
  }
}

function* checkExistingTransactions({ payload }: any) {
  if (!payload.reportResult.length) {
    yield put(push(moneyTransferRoutes.AMOUNT));
  }
}

function* updateRejectionConditions() {
  yield getUserData();
  yield put(push(authorizedRoutes.MONEY_TRANSFER));
}

const getUserDataFork = fork(requestSagaHandler.get, {
  actions: getUserDataActions,
  types: getUserDataTypes,
  url: GET_USER_DATA_ENDPOINT,
  navigate: {
    FAILURE: unAuthorizedRoutes.LOGIN_ROOT,
  },
  spinner: Spinners.GET_USER_DATA_SPINNER,
});

const verifyUserEmailFork = fork(requestSagaHandler.get, {
  actions: verifyUserEmailActions,
  types: verifyUserEmailTypes,
  paramsGetter: () => concatUrlWithUserId(VERIFY_USER_EMAIL_ENDPOINT),
});

const userResetForgottenPassword = fork(requestSagaHandler.post, {
  actions: userResetForgottenPasswordActions,
  paramsGetter: resetForgottenPasswordBodyGetter,
  types: userResetForgottenPasswordTypes,
  url: RESET_FORGOTTEN_PASSWORD_ENDPOINT,
  toastMessage: () => ({
    SUCCESS: i18next.t("password_has_been_reset"),
    FAILURE: i18next.t("failed_to_reset_password"),
  }),
});

const userResetForgottenPasswordEmail = fork(requestSagaHandler.post, {
  actions: userResetForgottenPasswordEmailActions,
  paramsGetter: resetForgottenPasswordEmailBodyGetter,
  types: userResetForgottenPasswordEmailTypes,
  url: RESET_FORGOTTEN_PASSWORD_EMAIL_ENDPOINT,
  toastMessage: () => ({
    SUCCESS: i18next.t("password_has_been_reset_email"),
    FAILURE: i18next.t("failed_to_reset_password"),
  }),
});

const saveUserNotificationsOptionsFork = fork(requestSagaHandler.post, {
  actions: saveUserNotificationOptionsActions,
  paramsGetter: saveUserNotificationOptionsBodyGetter,
  types: saveUserNotificationOptionsTypes,
  url: SAVE_USER_NOTIFICATION_OPTIONS_ENDPOINT,
  toastMessage: () => ({
    SUCCESS: i18next.t("options_have_been_saved"),
    FAILURE: i18next.t("failed_to_save_options"),
  }),
});

const getUserDocumentsFork = fork(requestSagaHandler.post, {
  actions: getUserDocumentsActions,
  paramsGetter: getUserIdBodyGetter,
  types: getUserDocumentsTypes,
  url: GET_USER_DOCUMENTS_ENDPOINT,
});

const getUserContactOptionsFork = fork(requestSagaHandler.post, {
  actions: getUserContactOptionsActions,
  paramsGetter: getUserContactOptionsTypesBodyGetter,
  types: getUserContactOptionsTypes,
  url: GET_USER_CONTACT_OPTIONS_ENDPOINT,
  spinner: Spinners.GET_USER_CONTACT_OPTIONS,
});

const verifyUserPhoneFork = fork(requestSagaHandler.post, {
  actions: verifyUserPhoneActions,
  paramsGetter: verifyUserPhoneBodyGetter,
  types: verifyUserPhoneTypes,
  url: VERIFY_USER_PHONE_ENDPOINT,
  toastMessage: () => ({
    SUCCESS: i18next.t("phone_number_has_been_verified"),
    FAILURE: i18next.t("code_is_incorrect"),
  }),
});

const verifyReferralCodeFork = fork(requestSagaHandler.post, {
  actions: verifyReferralCodeActions,
  paramsGetter: (referralCode: string) => ({ data: { referralCode } }),
  types: verifyReferralCodeTypes,
  url: VERIFY_REFERRAL_CODE_ENDPOINT,
});

const getAddressByZipCodeFork = fork(requestSagaHandler.post, {
  actions: getAddressByZipCodeActions,
  paramsGetter: getAddressByZipCodeBodyGetter,
  types: getAddressByZipCodeTypes,
  url: GET_ADDRESS_BY_ZIPCODE_ENDPOINT,
  spinner: Spinners.GET_AUTOCOMPLETE_OPTIONS,
});

const getUserTransactionsFork = fork(requestSagaHandler.post, {
  actions: getUserTransactionsActions,
  paramsGetter: getTransactionsBodyGetter,
  types: getUserTransactionsTypes,
  url: GET_USER_TRANSACTIONS_ENDPOINT,
  spinner: Spinners.TRANSACTIONS_SPINNER,
});

const getUserCardsFork = fork(requestSagaHandler.post, {
  actions: getUserCardsActions,
  paramsGetter: getUserIdBodyGetter,
  types: getUserCardsTypes,
  url: GET_USER_CARDS_ENDPOINT,
});

const sendEmailToOrganizationFork = fork(requestSagaHandler.post, {
  actions: sendEmailToOrganizationActions,
  paramsGetter: sendEmailToOrganizationBodyGetter,
  types: sendEmailToOrganizationTypes,
  url: SEND_EMAIL_TO_ORGANIZATION_ENDPOINT,
  toastMessage: () => ({
    SUCCESS: i18next.t("your_email_has_been_sent"),
    FAILURE: i18next.t("failed_to_send_the_email"),
  }),
});

const createUserCardFork = fork(requestSagaHandler.post, {
  actions: createCreditCardActions,
  types: createCreditCardTypes,
  url: CREATE_USER_CARD_ENDPOINT,
});

const removeUserCardFork = fork(requestSagaHandler.post, {
  types: removeCreditCardTypes,
  url: REMOVE_USER_CARD_ENDPOINT,
  actions: removeCreditCardActions,
});

export default function* userSaga() {
  yield all([
    userLoginFork,
    userTwoFAGenerateQRFork,
    userTwoFAVerifyFork,
    userTwoFADisableFork,
    editUserFork,
    getUserDocumentCheckIdReportFork,
    userSignupFork,
    getUserDocumentsFork,
    getUserTransactionsFork,
    getUserDataFork,
    userChangePasswordFork,
    userResetForgottenPassword,
    userResetForgottenPasswordEmail,
    saveUserNotificationsOptionsFork,
    getUserContactOptionsFork,
    sendVerificationCodeUnAuthFork,
    resendVerificationCodeFork,
    resendVerificationCodeByEmailFork,
    verifyUserPhoneFork,
    getAddressByZipCodeFork,
    sendEmailToOrganizationFork,
    verifyUserEmailFork,
    verifyReferralCodeFork,
    getUserCardsFork,
    createUserCardFork,
    removeUserCardFork,

    takeEvery([getUserDataTypes[FAILURE], USER_LOGOUT], logOutSaga),
    takeEvery(userLoginTypes[SUCCESS], handleLogin),
    takeEvery(userLoginTypes[FAILURE], handleLoginError),
    takeEvery(userTwoFAGenerateQRTypes[SUCCESS], handleTWOFAGenerateQRCode),
    takeEvery(userTwoFAVerifyTypes[SUCCESS], handleToggleTwoFAVerifyModal),
    takeEvery(userTwoFADisableTypes[SUCCESS], handleToggleTwoFADisableModal),
    takeEvery(
      userSignupLastStepTypes[SUCCESS],
      autologinUserAndCloseVerificationModal,
    ),
    takeEvery([uploadDocumentsTypes[SUCCESS]], getUserDocuments),
    takeEvery(getUserDataTypes[SUCCESS], successUserDataSaga),
    takeEvery(VERIFY_USER_CARD, cardVerificationSaga),
    takeEvery(getUserDocumentsTypes[SUCCESS], getDocumentsSources),
    // takeEvery(createNewTransactionTypes[SUCCESS], getUserTransactions),
    takeEvery(uploadDocumentsTypes[REQUEST], uploadUserDocs),
    takeEvery(uploadDocumentsTypes[SUCCESS], handleUploadDocsSuccess),
    // takeEvery(editUserTypes[SUCCESS], checkPhoneVerificationSaga),
    takeEvery(editUserTypes[SUCCESS], httpLogOutRequest),
    takeEvery(editUserTypes[SUCCESS], closeUpdateIdDataModal),
    takeEvery(verifyUserPhoneTypes[SUCCESS], closePhoneVerificationModalSaga),
    takeEvery(userSignupLastStepTypes[FAILURE], closeVerificationModalSaga),

    takeEvery(createCreditCardTypes[SUCCESS], getUserCards), // Re-fetch credit cards
    takeEvery(removeCreditCardTypes[SUCCESS], getUserCards), // Re-fetch credit cards
    takeEvery(getUserTransactionsTypes[SUCCESS], checkExistingTransactions),
    takeEvery(
      getUserDocumentCheckIdReportTypes[SUCCESS],
      toggleUpdateIdDataModal,
    ),
    takeEvery(USER_UPDATE_REJECTION_CONDITIONS, updateRejectionConditions),
  ]);

  yield call(getUserData);
}
