import {
  createActions,
  createConstants,
  createSetterAction,
} from "../../utils";

import {
  getSourceCurrenciesTypes,
  getDestinationCurrenciesTypes,
  getPriceTypes,
  SET_TRANSFER_CURRENCY,
  TransferDetailsConstants,
  SET_TRANSFER_DESTINATION_COUNTRY,
  SET_TRANSFER_PAYOUT_TYPE,
  RESET_PRICE_RESPONSE,
  SET_TRANSFER_RECIPIENT,
  SET_PURPOSE_OF_TRANSFER,
  SET_MOBILE_OPERATOR,
  SET_SOURCE_OF_FUNDS,
  getBanksByCountryTypes,
  getMobileOperatorsByCountryTypes,
  getMobileOperatorByPhoneNumber,
  getBankBranchesTypes,
  getPickupLocationsTypes,
  SET_PICKUP_LOCATION,
  SET_FUNDING_SOURCE,
  createNewTransactionTypes,
  SET_REVERSE_CALCULATION,
  getBankAccountDetailsTypes,
  SET_CREDIT_CARD_DETAILS,
  SET_SECURE_3D_INFO,
  getTransactionByQueryCodeTypes,
  PROCEED_TRANSACTION,
  PROCEED_TRANSACTION_TRUSTPAY,
  SET_CASH_CODE,
  APPLY_COMPLIANCE_RULE,
  CLEAR_COMPLIANCE_RULE,
  verifyPromocodeTypes,
  SET_TRANSFER_SOURCE_AMOUNT,
  SET_TRANSFER_DESTINATION_AMOUNT,
  VERIFY_AND_SUBMIT_TRANSACTION,
  getCellphoneProductsTypes,
  SET_TRANSFER_PRICE,
  RESET_TRANSFER_DETAILS,
  COMPLETE_TRANSACTION_WITHOUT_DOCS,
  QueryTypes,
  getProcessorBanksTypes,
  SET_BIC,
  SET_QUERY_BY_CODE_PAYLOAD,
  SET_TRANSFER_SOURCE_COUNTRY,
  PRINT_RECEIPT,
  SET_RECEIPT_DATA,
  SET_TRANSFER_RECIPIENT_PHONE,
  SET_TRANSFER_RECIPIENT_BANK_ACCOUNT,
  SET_AIRTIME_PRODUCT,
  SET_TRANSFER_CARD_NUMBER,
  getBankByIfscTypes,
  RESET_IFSC_DETAILS,
  getCitiesByCountryTypes,
  getBranchesByCityTypes,
  SET_RESEND_DATA,
  SET_SPLIT_TRANSACTION,
  checkComplianceTypes,
  SET_BANK_EXTERNAL_CODE,
  SET_TRANSACTION_START_TIME,
  verifiedRecipientAccount,
  setResipientAccountVerified,
  SET_RECIPIENT_ACCOUNT_VERIFIED,
  SET_FEE_VISIBILITY,
} from "./transfer.constants";
import {
  IRecipientAccountVerificationData,
  ITransferData,
} from "./transfer.types";

// calculator actions
const {
  SOURCE_CURRENCY,
  DESTINATION_CURRENCY,
  DESTINATION_COUNTRY,
  PAYOUT_TYPE,
  SOURCE_AMOUNT,
  DESTINATION_AMOUNT,
  TRANSFER_RECIPIENT,
  SOURCE_OF_FUNDS,
  PURPOSE_OF_TRANSFER,
  PICKUP_LOCATION,
  BANK_EXTERNAL_CODE,
  FUNDING_SOURCE,
  REVERSE_CALCULATION,
  CREDIT_CARD_DETAILS,
  SECURE_3D_INFO,
  CASH_CODE,
  ACTIVE_COMPLIANCE_RULE,
  MOBILE_OPERATOR,
  TRANSFER_PRICE,
  BIC,
  QUERY_BY_CODE_PAYLOAD,
  SOURCE_COUNTRY,
  RECEIPT_DATA,
  AIR_TIME_PHONE,
  AIR_TIME_PRODUCT,
  CARD_NUMBER,
  FEE_VISIBILITY,
} = TransferDetailsConstants;

export const getSourceCurrenciesActions = createActions(
  getSourceCurrenciesTypes,
);
export const getDestinationCurrenciesActions = createActions(
  getDestinationCurrenciesTypes,
);

export const getCitiesByCountryActions = createActions(getCitiesByCountryTypes);
export const getBranchesByCityActions = createActions(getBranchesByCityTypes);
export const getPriceActions = createActions(getPriceTypes);
export const getBankByIfscActions = createActions(getBankByIfscTypes);
export const resetIfscDetailsAction = () => ({ type: RESET_IFSC_DETAILS });
export const getBanksByCountryActions = createActions(getBanksByCountryTypes);
export const getMobileOperatorsByCountryActions = createActions(
  getMobileOperatorsByCountryTypes,
);
export const getMobileOperatorByPhoneNumberActions = createActions(
  getMobileOperatorByPhoneNumber,
);
export const getBankBranchesActions = createActions(getBankBranchesTypes);
export const getPickupLocationsActions = createActions(getPickupLocationsTypes);
export const createNewTransactionActions = createActions(
  createNewTransactionTypes,
);
export const verifyPromocodeActions = createActions(verifyPromocodeTypes);

export const getBankAccountDetailsActions = createActions(
  getBankAccountDetailsTypes,
);

export const getTransactionByQueryCodeActions = createActions(
  getTransactionByQueryCodeTypes,
);
export const getCellphoneProductsActions = createActions(
  getCellphoneProductsTypes,
);
export const getProcessorBanksActions = createActions(getProcessorBanksTypes);
export const checkComplianceActions = createActions(checkComplianceTypes);

export const getRecipientAccountVerifiedAction = createActions(
  verifiedRecipientAccount,
);

export const setResipientAccountVerifiedAction = (
  payload: IRecipientAccountVerificationData,
) => ({
  type: SET_RECIPIENT_ACCOUNT_VERIFIED,
  payload,
});

export const setDestinationCountryAction = createSetterAction(
  SET_TRANSFER_DESTINATION_COUNTRY,
  DESTINATION_COUNTRY,
);

export const setSourceCountryAction = createSetterAction(
  SET_TRANSFER_SOURCE_COUNTRY,
  SOURCE_COUNTRY,
);

export const storeComplianceRuleDataAction = createSetterAction(
  APPLY_COMPLIANCE_RULE,
  ACTIVE_COMPLIANCE_RULE,
);
export const clearComplianceRuleAction = createSetterAction(
  CLEAR_COMPLIANCE_RULE,
  ACTIVE_COMPLIANCE_RULE,
);
export const setPayoutTypeAction = createSetterAction(
  SET_TRANSFER_PAYOUT_TYPE,
  PAYOUT_TYPE,
);
export const setSourceCurrencyAction = createSetterAction(
  SET_TRANSFER_CURRENCY,
  SOURCE_CURRENCY,
);
export const setDestinationCurrencyAction = createSetterAction(
  SET_TRANSFER_CURRENCY,
  DESTINATION_CURRENCY,
);
export const setSourceAmountAction = createSetterAction(
  SET_TRANSFER_SOURCE_AMOUNT,
  SOURCE_AMOUNT,
);
export const setDestinationAmountAction = createSetterAction(
  SET_TRANSFER_DESTINATION_AMOUNT,
  DESTINATION_AMOUNT,
);

export const setTransferRecipientAction = createSetterAction(
  SET_TRANSFER_RECIPIENT,
  TRANSFER_RECIPIENT,
);

export const setReverseCalculation = createSetterAction(
  SET_REVERSE_CALCULATION,
  REVERSE_CALCULATION,
);

export const setPurposeOfTransferAction = createSetterAction(
  SET_PURPOSE_OF_TRANSFER,
  PURPOSE_OF_TRANSFER,
);

export const setSourceOfFundsAction = createSetterAction(
  SET_SOURCE_OF_FUNDS,
  SOURCE_OF_FUNDS,
);

export const setTransferCardNumberAction = createSetterAction(
  SET_TRANSFER_CARD_NUMBER,
  CARD_NUMBER,
);

export const setTransferRecipientBankAccountAction = createSetterAction(
  SET_TRANSFER_RECIPIENT_BANK_ACCOUNT,
  TRANSFER_RECIPIENT,
);

export const setPickupLocationAction = createSetterAction(
  SET_PICKUP_LOCATION,
  PICKUP_LOCATION,
);

export const setBankExternalCodeAction = createSetterAction(
  SET_BANK_EXTERNAL_CODE,
  BANK_EXTERNAL_CODE,
);

export const setMobileOperatorAction = createSetterAction(
  SET_MOBILE_OPERATOR,
  MOBILE_OPERATOR,
);

export const setFundingSourceAction = createSetterAction(
  SET_FUNDING_SOURCE,
  FUNDING_SOURCE,
);

export const setCreditCardDetailsAction = createSetterAction(
  SET_CREDIT_CARD_DETAILS,
  CREDIT_CARD_DETAILS,
);

export const setSecure3dInfoAction = createSetterAction(
  SET_SECURE_3D_INFO,
  SECURE_3D_INFO,
);

export const setTransferPriceAction = createSetterAction(
  SET_TRANSFER_PRICE,
  TRANSFER_PRICE,
);

export const setFeeVisibility = createSetterAction(
  SET_FEE_VISIBILITY,
  FEE_VISIBILITY,
);

export const setBICAction = createSetterAction(SET_BIC, BIC);

export const setCashCodeAction = createSetterAction(SET_CASH_CODE, CASH_CODE);

export const setQueryByCodePayloadAction = createSetterAction(
  SET_QUERY_BY_CODE_PAYLOAD,
  QUERY_BY_CODE_PAYLOAD,
);

export const setResendTransferAction = (payload: ITransferData) => ({
  type: SET_RESEND_DATA,
  payload,
});

export const setTransactionStartTime = (payload: string) => ({
  type: SET_TRANSACTION_START_TIME,
  payload,
});

export const setSplitTransactionAction = (payload: boolean) => ({
  type: SET_SPLIT_TRANSACTION,
  payload,
});

export const resetTransferPriceAction = () => ({
  type: RESET_PRICE_RESPONSE,
});

export const verifyAndSubmitTransactionAction = () => ({
  type: VERIFY_AND_SUBMIT_TRANSACTION,
});

export const resetTransferDetailsAction = () => ({
  type: RESET_TRANSFER_DETAILS,
});

export const proceedTranactionAction = () => ({
  type: PROCEED_TRANSACTION,
});

export const proceedTransactionActionTrustPay = () => ({
  type: PROCEED_TRANSACTION_TRUSTPAY,
});

export const completeTransactionWithoutDocumentsAction = () => ({
  type: COMPLETE_TRANSACTION_WITHOUT_DOCS,
});

export const printReceiptAction = (payload: {
  queryCode: string;
  queryType: QueryTypes;
}) => ({
  type: PRINT_RECEIPT,
  payload,
});

export const setReceiptDataAction = createSetterAction(
  SET_RECEIPT_DATA,
  RECEIPT_DATA,
);

export const setAirTimePhoneAction = createSetterAction(
  SET_TRANSFER_RECIPIENT_PHONE,
  AIR_TIME_PHONE,
);

export const setAirTimeProductAction = createSetterAction(
  SET_AIRTIME_PRODUCT,
  AIR_TIME_PRODUCT,
);
