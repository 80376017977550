import { createActions, createSetterAction } from "../../utils";

import {
  changePasswordTypes,
  CLEAR_USER_DATA,
  createCreditCardTypes,
  editUserTypes,
  getAddressByZipCodeTypes,
  getUserCardsTypes,
  getUserContactOptionsTypes,
  getUserDataTypes,
  getUserDocumentCheckIdReportTypes,
  getUserDocumentsTypes,
  getUserTransactionsTypes,
  removeCreditCardTypes,
  resendVerificationCodeByEmailTypes,
  resendVerificationCodeTypes,
  RESET_REFERRAL_CODE_DATA,
  saveUserNotificationOptionsTypes,
  sendEmailToOrganizationTypes,
  sendVerificationCodeUnAuthTypes,
  SET_CARD_FORM,
  SET_SIGNUP_DOCS,
  SET_USER_DOCS,
  uploadDocumentsTypes,
  uploadImageTypes,
  USER_CLEAN_UP_LOGIN_ERROR,
  USER_LOGOUT,
  USER_SET_REJECTION_FLOW,
  USER_SIGNUP_FIRST_STEP,
  USER_UPDATE_REJECTION_CONDITIONS,
  userLoginTypes,
  userResetForgottenPasswordEmailTypes,
  userResetForgottenPasswordTypes,
  userResetPasswordTypes,
  userSignupLastStepTypes,
  UserStoreConstants,
  userTwoFADisableTypes,
  userTwoFAGenerateQRTypes,
  userTwoFAVerifyTypes,
  verifyReferralCodeTypes,
  verifyUserEmailTypes,
  verifyUserPhoneTypes,
} from "./user.constants";
import { IRejectionFlowState, ISignupFormValues } from "./user.types";

const { USER_DOCUMENTS, SIGNUP_DOCUMENTS } = UserStoreConstants;

export const setCreditCardForm = (form: any) => ({ type: SET_CARD_FORM, form });

export const VERIFY_USER_CARD = "VERIFY_USER_CARD";

export const tokenizeCreditCard = (card: any) => ({
  type: VERIFY_USER_CARD,
  card,
});

export const setSignupDocumentssAction = createSetterAction(
  SET_SIGNUP_DOCS,
  SIGNUP_DOCUMENTS,
);

export const createCreditCardActions = createActions(createCreditCardTypes);
export const removeCreditCardActions = createActions(removeCreditCardTypes);

export const userLoginActions = createActions(userLoginTypes);
export const userTwoFAGenerateQRActions = createActions(
  userTwoFAGenerateQRTypes,
);
export const userTwoFAVerifyActions = createActions(userTwoFAVerifyTypes);
export const userTwoFADisableActions = createActions(userTwoFADisableTypes);
export const uploadDocumentsActions = createActions(uploadDocumentsTypes);
export const uploadImageActions = createActions(uploadImageTypes);
export const userResetPasswordActions = createActions(userResetPasswordTypes);
export const userResetForgottenPasswordActions = createActions(
  userResetForgottenPasswordTypes,
);
export const userResetForgottenPasswordEmailActions = createActions(
  userResetForgottenPasswordEmailTypes,
);
export const getUserDataActions = createActions(getUserDataTypes);
export const getUserCardsActions = createActions(getUserCardsTypes);
export const getUserDocumentsActions = createActions(getUserDocumentsTypes);
export const saveUserNotificationOptionsActions = createActions(
  saveUserNotificationOptionsTypes,
);
export const getUserContactOptionsActions = createActions(
  getUserContactOptionsTypes,
);
export const resendVerificationCodeActions = createActions(
  resendVerificationCodeTypes,
);

export const resendVerificationCodeByEmailActions = createActions(
  resendVerificationCodeByEmailTypes,
);

export const verifyUserEmailActions = createActions(verifyUserEmailTypes);

export const sendVerificationCodeUnAuthActions = createActions(
  sendVerificationCodeUnAuthTypes,
);

export const verifyUserPhoneActions = createActions(verifyUserPhoneTypes);

export const setSignupFirstStepDataAction = (payload: ISignupFormValues) => ({
  type: USER_SIGNUP_FIRST_STEP,
  payload,
});

export const setRejectionFlowAction = (payload: IRejectionFlowState) => ({
  type: USER_SET_REJECTION_FLOW,
  payload,
});

export const updateRejectionConditionsAction = () => ({
  type: USER_UPDATE_REJECTION_CONDITIONS,
});

export const userSignupLastStepActions = createActions(userSignupLastStepTypes);
export const changePasswordActions = createActions(changePasswordTypes);
export const editUserActions = createActions(editUserTypes);
export const getUserDocumentCheckIdReportActions = createActions(
  getUserDocumentCheckIdReportTypes,
);
export const getAddressByZipCodeActions = createActions(
  getAddressByZipCodeTypes,
);
export const sendEmailToOrganizationActions = createActions(
  sendEmailToOrganizationTypes,
);

export const userCleanUpLoginError = () => ({
  type: USER_CLEAN_UP_LOGIN_ERROR,
});

export const setDocumentsAction = createSetterAction(
  SET_USER_DOCS,
  USER_DOCUMENTS,
);

export const logoutUserAction = () => ({
  type: USER_LOGOUT,
});

export const clearUserDataAction = () => ({
  type: CLEAR_USER_DATA,
});

export const getUserTransactionsActions = createActions(
  getUserTransactionsTypes,
);

export const resetReferralCodeDataAction = () => ({
  type: RESET_REFERRAL_CODE_DATA,
});

export const verifyReferralCodeActions = createActions(verifyReferralCodeTypes);
